<template>
  <div class="rankingSearch">
    <!-- <div class="main-Title bgff">
      <h2>Ranking & Search</h2>
    </div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" class="form_calss" size="mini">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="20" :md="20" :lg="19" :xl="21">
              <el-row>
                <!-- Source -->
                <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="max-height: 34px;">
                  <el-form-item label="Source" label-width="65px" class="mb5">
                    <group-select
                      v-model="filter.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      collapse-tags
                      :loading="sourceLoading"
                      clearable
                      reserve-keyword
                      filterable
                      class="w100"
                    />
                  </el-form-item>
                </el-col>
                <!-- sourceName -->
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="sourceName" label-width="100px" class="mb5">
                    <el-input v-model="filter.sourceName" class="w100"></el-input>
                  </el-form-item>
                </el-col>
                <!-- Source Offer -->
                <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                  <el-form-item label="Source Offer" label-width="100px" class="mb5">
                    <el-input
                      v-model="filter.sourceOfferIds"
                      placeholder="source offer id here. eg: 123,456"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- Countries -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Countries" label-width="80px" class="mb5">
                    <el-input v-model="filter.countries" placeholder="countries here"></el-input>
                  </el-form-item>
                </el-col>
                <!-- Offer ID -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Offer ID" label-width="65px" class="mb5">
                    <el-input v-model="filter.offerIds" placeholder="offer id here. eg: 123,456">
                    </el-input>
                  </el-form-item>
                </el-col>
                <!-- Offer Name -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Offer Name" label-width="100px" class="mb5">
                    <el-input v-model="filter.offerName" placeholder="offer name here"></el-input>
                  </el-form-item>
                </el-col>
                <!-- Offer Status -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" style="max-height: 34px;">
                  <el-form-item label="Offer Status" label-width="100px" class="mb5">
                    <el-select v-model="filter.status" filterable clearable class="w100">
                      <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- Package Name -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Package Name" label-width="110px" class="mb5">
                    <el-input
                      v-model="filter.prodName"
                      placeholder="package name here"
                      class="w100"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- Ranking By -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Ranking By" label-width="85px" class="mb5">
                    <el-select v-model="filter.ranking" filterable clearable>
                      <el-option
                        v-for="item in rankingOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- Lowest payout -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Lowest payout" label-width="110px" class="mb5">
                    <el-input
                      v-model="filter.lowPayout"
                      placeholder="payout value here"
                      class="w100"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- Highest payout -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Highest payout" label-width="120px" class="mb5">
                    <el-input
                      v-model="filter.highPayout"
                      placeholder="payout value here"
                      class="w100"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- Market -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Market" label-width="60px" class="mb5">
                    <el-select v-model="filter.market" filterable clearable class="w100">
                      <el-option
                        v-for="item in marketOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- Redirect Status -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Redirect Status" label-width="110px" class="mb5">
                    <el-select v-model="filter.redirectStatus" filterable clearable class="w100">
                      <el-option
                        v-for="item in rankingStatusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- MaxRedirectCount -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="MaxRedirectCount" label-width="130px" class="mb5">
                    <el-input v-model="filter.maxRedirectCount"></el-input>
                  </el-form-item>
                </el-col>
                <!-- pid -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="pid" label-width="50px" class="mb5">
                    <el-select
                      v-model="filter.pids"
                      filterable
                      clearable
                      multiple
                      collapse-tags
                      class="w100"
                    >
                      <el-option v-for="item in pids" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- offer Link Type -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="offer Link Type" label-width="120px" class="mb5">
                    <el-select
                      v-model="filter.offerTypes"
                      filterable
                      clearable
                      multiple
                      collapse-tags
                      class="w100"
                    >
                      <el-option label="rt" value="rt"></el-option>
                      <el-option label="ua" value="ua"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- manager -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="PM" label-width="70px">
                    <el-select v-model="filter.sourceManager" filterable clearable class="w100">
                      <el-option
                        v-for="item in managerOption"
                        :key="item.accountId"
                        :label="item.username"
                        :value="item.username"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- pbdomain -->
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                  <el-form-item label="pb-domain" label-width="100px" class="mb5">
                    <el-select
                      v-model="filter.pbDomains"
                      filterable
                      clearable
                      multiple
                      collapse-tags
                      class="w100"
                    >
                      <el-option
                        v-for="item in pbDomains"
                        :key="item.id"
                        :label="item.pbDomain"
                        :value="item.pbDomain"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>

            <el-col :xs="24" :sm="4" :md="4" :lg="5" :xl="3">
              <el-row :gutter="10">
                <el-col :md="24" :lg="12">
                  <el-button type="primary" class="w100 mb5" @click="getOfferList(1)" size="mini"
                    >Search</el-button
                  >
                </el-col>
                <el-col :span="12" style="max-height: 33px;">
                  <upload
                    :action="doUpload"
                    class="uploadBtn mb5"
                    @handleUploadSuccess="handleUploadSuccess"
                  >
                  </upload>
                </el-col>
                <!--                <el-col :span="12">-->
                <!--                  <el-button-->
                <!--                    type="primary"-->
                <!--                    class="w100 mb5"-->
                <!--                    @click="offersVisible = true"-->
                <!--                    size="mini"-->
                <!--                    >ShowIds</el-button-->
                <!--                  >-->
                <!--                </el-col>-->
                <!--                <el-col :span="12">-->
                <!--                  <el-button-->
                <!--                    type="primary"-->
                <!--                    class="w100 mb5"-->
                <!--                    v-clipboard:copy="copyArr"-->
                <!--                    v-clipboard:success="onCopy"-->
                <!--                    v-clipboard:error="onError"-->
                <!--                    size="mini"-->
                <!--                    >CopyIds-->
                <!--                  </el-button>-->
                <!--                </el-col>-->
                <!--                <el-col :span="12">-->
                <!--                  &lt;!&ndash; 清空按钮 &ndash;&gt;-->
                <!--                  <el-button type="info" class="w100 mb5" @click="setNullInput" size="mini"-->
                <!--                    >SetEmpty-->
                <!--                  </el-button>-->
                <!--                </el-col>-->
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-button type="primary" class="w100 mb5" @click="downloadClick" size="mini">
                    Download</el-button
                  >
                </el-col>
                <el-col :span="12">
                  <el-button
                    type="success"
                    class="w100 mb5"
                    style="background-color:#009688;"
                    @click="batchClick(1)"
                    size="mini"
                    >Batch Hold</el-button
                  >
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-button
                    type="success"
                    class="w100 mb5"
                    style="background-color:#009688;"
                    @click="batchClick(2)"
                    size="mini"
                    >Batch Active</el-button
                  >
                </el-col>
                <el-col :span="12">
                  <el-button type="primary" class="w100 mb5" @click="batchClick(3)" size="mini"
                    >File Template</el-button
                  >
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <upload
                    :action="fileTemplateUrl"
                    buttonTxt="Batch Import"
                    class="uploadBtn mb5"
                    fileType="xlsx"
                    :isIcon="false"
                    :on-success="handleBatchImportUploadSuccess"
                  >
                  </upload>
                </el-col>
                <el-col :span="12">
                  <el-button
                    type="success"
                    class="w100 mb5"
                    style="background-color:#009688;"
                    @click="batchOfferTest()"
                    size="mini"
                    >Batch Test</el-button
                  >
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-button
                    type="success"
                    class="w100 mb5"
                    style="background-color:#009688;"
                    @click="updateTmpressionClick()"
                    size="mini"
                    >batch Impression</el-button
                  >
                </el-col>
                <el-col :span="12">
                  <el-button
                    type="success"
                    class="w100 mb5"
                    style="background-color:#009688;"
                    @click="batchUpdateOfferSourceLink('view')"
                    size="mini"
                    >Update Link</el-button
                  >
                </el-col>
                <el-col :span="12">
                  <el-button
                    type="success"
                    class="w100 mb5"
                    style="background-color:#009688;"
                    @click="
                      copyArr.length > 0 ? updateBatchEventClick() : $message.error('请选择offer')
                    "
                    size="mini"
                    >batch Event</el-button
                  >
                </el-col>
              </el-row>
              <!-- <el-row :gutter="10">
                <el-col :span="24">
                  <el-button
                    type="success"
                    class="w100"
                    style="background-color:#009688;"
                    @click="replacePbVisible = true"
                    size="mini"
                  >
                    Replace PB (Man)
                  </el-button>
                </el-col>
              </el-row> -->
            </el-col>
          </el-row>
        </el-form>
        <!-- 选中checkbox弹出 -->
        <!-- v-show="multipleSelection.length !== 0" -->
        <!-- 2023-03-13移除 -->
        <el-form
          ref="checkedBox"
          :model="checkedBox"
          :rules="rules"
          size="small"
          v-show="multipleSelection.length !== 0"
          class="list-filter"
        >
          <el-row :gutter="5">
            <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4">
              <el-form-item label="Affiliate Id" label-width="80px" class="mb5">
                <group-select
                  size="small"
                  v-model="checkedBox.affiliateIds"
                  :data-source="affiliatesGroupList"
                  multiple
                  collapse-tags
                  clearable
                  reserve-keyword
                  filterable
                  placeholder="请选择"
                  hasAll
                  :loading="affiliateLoading"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4">
              <el-form-item label="DailyCap" prop="dailyCap" label-width="80px" class="mb5">
                <el-input
                  v-model.number="checkedBox.dailyCap"
                  placeholder="add cap here"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4">
              <el-form-item label="Payout" prop="payout" label-width="70px" class="mb5">
                <el-input
                  v-model="checkedBox.payout"
                  placeholder="add payout here"
                  @change="limitPayoutOrPercent(1)"
                  :disabled="payoutFlag"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4">
              <el-form-item label="Payout per" label-width="90px" class="mb5">
                <el-input
                  v-model="checkedBox.payoutPercent"
                  placeholder="payoutPercent"
                  @change="limitPayoutOrPercent(2)"
                  :disabled="percentFlag"
                >
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4">
              <el-form-item label="Adjust" label-width="60px" class="mb5">
                <el-input v-model="checkedBox.rate" placeholder="add adjust rate">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :lg="4" :xl="4">
              <el-form-item label="Description" label-width="90px" class="mb5">
                <el-input v-model="checkedBox.description" placeholder="add description"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item class="mb5">
                <el-button type="primary" size="small" @click="confirmaddToAff('checkedBox')">
                  confirm
                </el-button>
                <el-button type="primary" size="small" @click="BatchMix()">
                  batchMix
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <div class="p10 bgff">
        <el-table
          :data="offerList"
          border
          stripe
          highlight-current-row
          v-loading="listLoading"
          size="mini"
          @selection-change="handleSelectionChange"
          height="800"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
          ></el-table-column>
          <el-table-column fixed label="Status" prop="status" align="center" width="70">
            <template slot-scope="scope">
              <span>{{ scope.row.status }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed label="OfferId" prop="offerId" align="center" width="80">
            <template slot-scope="scope">
              <span @click="detailClick(scope.row)" class="cor337ab7">{{ scope.row.offerId }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed label="ProdName" prop="prodName" align="center" width="110">
            <template slot-scope="scope">
              {{ scope.row.prodName }}
              <i
                class="el-icon-edit"
                @click="updateProdName(scope.row.offerId, scope.row.platforms, scope.row.prodName)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column fixed label="OfferName" prop="offerName" align="center" min-width="100">
            <template slot-scope="scope">
              {{ scope.row.offerName }}
              <i
                class="el-icon-edit"
                @click="updateOfferName(scope.row.offerId, scope.row.offerName)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            label="sourcetracklink"
            prop="trackingLink"
            align="center"
            min-width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.trackingLink }}
              <div style="position: absolute;"
                ><i
                  class="el-icon-edit"
                  @click="updateOfferSource(scope.row.offerId, scope.row.trackingLink)"
                ></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Payout" prop="payout" align="center" width="70">
            <template slot-scope="scope">
              {{ scope.row.payout || 0 }}
              <i class="el-icon-edit" @click="updatePayout(scope.row.offerId)"></i>
            </template>
          </el-table-column>
          <el-table-column
            label="Revenue"
            prop="revenue"
            align="center"
            width="70"
          ></el-table-column>
          <el-table-column
            label="impressionTracking"
            prop="impressionTracking"
            align="center"
            width="80"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="PM"
            prop="sourceManager"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column label="Pid" prop="pid" align="center" min-width="100">
            <template slot-scope="scope">
              {{ scope.row.pid }}
              <i
                class="el-icon-edit"
                @click="updateOfferLinkType(scope.row.offerId, scope.row.pid, 'pid')"
              ></i>
            </template>
          </el-table-column>
          <el-table-column label="offer Link Type" prop="offerType" align="center" width="80">
            <template slot="header">
              offer<br />
              Link Type
            </template>
            <template slot-scope="scope">
              {{ scope.row.offerType }}
              <i
                class="el-icon-edit"
                @click="
                  updateOfferLinkType(scope.row.offerId, scope.row.offerType, 'offerLinkType')
                "
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            label="SourceName"
            prop="sourceName"
            align="center"
            min-width="110"
          ></el-table-column>
          <el-table-column
            label="Countries"
            prop="countries"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column label="offerPayoutType" prop="offerPayoutType" align="center" width="60">
            <template slot-scope="scope">
              {{ scope.row.offerPayoutType }}
              <i
                class="el-icon-edit"
                @click="clickPayoutType(scope.row.offerId, scope.row.offerPayoutType)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column label="pb domain" prop="pid" align="center" min-width="100">
            <template slot-scope="scope">
              {{ scope.row.pbDomain }}
            </template>
          </el-table-column>
          <el-table-column label="ICON" align="center" width="70">
            <!-- :fixed="fullWidth <= 768 ? false : 'left'" -->
            <template slot-scope="scope">
              <a :href="scope.row.previewLink" target="_blank">
                <img :src="scope.row.icon" class="icon" />
              </a>
            </template>
          </el-table-column>
          <el-table-column
            label="important"
            prop="important"
            align="center"
            width="60"
          ></el-table-column>
          <el-table-column label="sub_count" prop="subCount" align="center" width="60">
            <template slot="header"> Sub<br />Count</template>
            <template slot-scope="scope">
              <span class="cor337ab7" @click="subCountFun(scope.row)">{{
                scope.row.subCount
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="ruleName" prop="ruleName" align="center" min-width="100">
            <template slot-scope="scope">
              {{ scope.row.ruleName }}
              <i
                class="el-icon-edit"
                @click="updateManagerAndSubRule(scope.row.offerId, scope.row.ruleId)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column label="trackStatus" prop="trackStatus" align="center" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.trackStatus === '0'">失败</span>
              <span v-if="scope.row.trackStatus === '-1'">异常</span>
              <span v-if="scope.row.trackStatus === '1'">成功</span>
            </template>
          </el-table-column>
          <el-table-column
            label="trackCount"
            prop="trackCount"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="SourceOfferId" prop="sourceOfferId" align="center" width="90">
            <template slot="header">Source<br />OfferId</template>
          </el-table-column>
          <el-table-column label="SourceId" align="center" width="80">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="DurationTime" prop="duration" align="center" width="80">
            <template slot="header"> Duration<br />Time </template>
          </el-table-column>
          <el-table-column label="PayoutType" prop="payoutType" align="center" width="70">
            <template slot="header">Payout<br />Type </template>
          </el-table-column>
          <el-table-column label="OfferNameIden" prop="offerName" align="center" width="70">
            <template slot="header">Offer<br />NameIden</template>
            <template slot-scope="scope">
              <span v-if="scope.row.offerName && scope.row.offerName.indexOf('CPE') !== -1"
                >CPE</span
              >
              <span v-else-if="scope.row.offerName && scope.row.offerName.indexOf('CPA') !== -1"
                >CPA</span
              >
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Platforms"
            prop="platforms"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column label="OpenTime" prop="openTime" align="center" width="90">
            <template slot="header">Open<br />Time</template>
          </el-table-column>
          <el-table-column label="CloseTime" prop="closeTime" align="center" width="90">
            <template slot="header">Close<br />Time</template>
          </el-table-column>
          <el-table-column label="Market" prop="market" align="center"></el-table-column>

          <el-table-column label="Option" align="center" width="100" fixed="right">
            <template slot-scope="scope">
              <el-dropdown>
                <el-button type="primary" size="mini">
                  More<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <template v-if="scope.row.status == 'active'">
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="success"
                        style="width:100%"
                        @click="change_Status(scope.row.offerId)"
                        >Hold</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="primary"
                        style="width:100%"
                        @click="copy_offer(scope.column, scope.row)"
                        >Copy</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="primary"
                        style="width:100%"
                        @click="copyOfferInfo(scope.row)"
                        >Cut</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="danger"
                        style="width:100%"
                        @click="update_offer(scope.row)"
                        >Update</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button size="mini" type="primary" @click="subLogFun(scope.row)">
                        sub_log</el-button
                      >
                    </el-dropdown-item>
                  </template>
                  <template v-else-if="scope.row.status == 'hold'">
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="success"
                        @click="change_Status(scope.row.offerId)"
                        >Pending</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="primary"
                        style="width:100%"
                        @click="copy_offer(scope.column, scope.row)"
                        >Copy</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button size="mini" type="danger" @click="update_offer(scope.row)"
                        >Update</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button size="mini" type="primary" @click="subLogFun(scope.row)">
                        sub_log</el-button
                      >
                    </el-dropdown-item>
                  </template>
                  <template v-else>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="success"
                        style="width:100%"
                        @click="change_Status(scope.row.offerId)"
                        >Active</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="primary"
                        @click="copy_offer(scope.column, scope.row)"
                        >Copy</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button size="mini" type="danger" @click="update_offer(scope.row)"
                        >Update</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button size="mini" type="primary" @click="subLogFun(scope.row)">
                        sub_log</el-button
                      >
                    </el-dropdown-item>
                  </template>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <!-- +++++++++++++++ -->
          <!-- <el-table-column label="TrackStatus" min-width="110">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.trackStatus == '0'" type="danger">跳转失败</el-tag>
              <el-tag v-else-if="scope.row.trackStatus == '1'" type="success">跳转成功</el-tag>
              <el-tag v-else type="info">请求失败</el-tag>
            </template>
          </el-table-column> -->
          <!-- <el-table-column label="TrackingCount" min-width="130">
            <template slot-scope="scope">
              <el-tooltip effect="dark" placement="left-start">
                <div slot="content" v-html="filterDomins(scope.row.domains)"></div>
                <el-button size="mini" v-if="scope.row.trackCount"
                  >Track {{ scope.row.trackCount }} times</el-button
                >
                <el-button size="mini" v-else>UNKNOW</el-button>
              </el-tooltip>
            </template>
          </el-table-column> -->
          <!-- <el-table-column label="TaskStatus" prop="openStatus" align="center" min-width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.openStatus }}</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column label="TrafficTag" prop="trafficTag" align="center" min-width="150">
            <template slot-scope="scope" v-if="scope.row.trafficTag">
              <el-tag type="success" v-for="item in scope.row.trafficTag.split(',')" :key="item">
                {{ item }}</el-tag
              >
            </template>
          </el-table-column> -->
        </el-table>
        <div class="align-c">
          <pagination
            class="block pagePanel"
            :pageTotal="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :pageSize="pageParam.pageSize"
            :currentPage="pageParam.page"
          ></pagination>
        </div>
      </div>
    </div>
    <!--BatchMix操作-->
    <el-dialog title="Auto mix操作" width="30%" :visible.sync="autoMix.visible" destroy-on-close>
      <auto-mix
        type="offerList"
        :multipleSelection="multipleSelection"
        :handleCloseDialog="closeMixDialog"
        :openErrorDialog="openErrorDialog"
        :message="parentMessage"
        :selectCountries="countries"
        ref="getAutoMix"
      ></auto-mix>
    </el-dialog>
    <el-dialog title="offerIds" width="30%" :visible.sync="offersVisible">
      <span style="font-size:16px;">{{ offers }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="offersVisible = false">确定</el-button>
      </span>
    </el-dialog>
    <!-- 批单提示框 -->
    <el-dialog title="批单信息" :visible.sync="toAffVisible">
      <el-table :data="tipsData" stripe border>
        <el-table-column label="AffiliateId" prop="affiliateId" align="center"></el-table-column>
        <el-table-column label="Type & Msg" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type">{{ scope.row.msg }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="OfferIds" prop="offerIds" align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="Update Offer Payout" :visible.sync="payoutSettingVisible" width="500px">
      <el-form :model="offerPayout" label-position="left">
        <el-form-item label="Payout">
          <el-input
            v-model="offerPayout.payout"
            :disabled="offerPayout.controlType !== 'api'"
          ></el-input>
        </el-form-item>
        <el-form-item label="revenue">
          <el-input v-model="offerPayout.revenue"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payoutSettingVisible = false">返回</el-button>
        <el-button type="primary" @click="offerPayoutChange">确 定</el-button>
      </span>
    </el-dialog>
    <!-- sub log -->
    <el-dialog title="Sub Log" :visible.sync="subLog.visible">
      <el-table :data="subLog.list" stripe border height="70vmin">
        <el-table-column label="Sub ID" prop="subId" align="center"></el-table-column>
        <el-table-column label="off time" prop="offTime" align="center"></el-table-column>
        <el-table-column label="msg" prop="message" align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- sub count -->
    <el-dialog title="Sub Count" :visible.sync="subLog.countVisible">
      <el-table :data="subLog.countList" stripe border>
        <el-table-column label="Sub ID" prop="subId" align="center"></el-table-column>
        <el-table-column label="On Time" prop="onTime" align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 更新payout -->
    <el-dialog
      title="update Payout"
      :visible.sync="payout.visible"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="payout.list"
        label-position="top"
        size="mini"
        ref="updatePayoutAll"
        :rules="payoutInfoRules"
        v-loading="payout.loading"
      >
        <el-row :gutter="10">
          <el-col :lg="5" :md="5">
            <el-form-item label="Event name">
              <el-input disabled value="install" placeholder="please add Event name..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item label="Revenue" prop="revenue">
              <el-input
                :disabled="payout.list.controlType === 'api'"
                v-model="payout.list.revenue"
                placeholder="please add revenue..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item label="Payout" prop="payout">
              <el-input
                v-model="payout.list.payout"
                placeholder="please add payout..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item label="Install Cap Daily">
              <el-input
                :disabled="payout.list.controlType === 'api'"
                v-model="payout.list.capDaily"
                placeholder="please add cap daily..."
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :lg="4" :md="4">
            <el-form-item label="Manual Cap Daily">
              <el-input
                v-model="payout.list.manualCapDaily"
                placeholder="please add manual cap daily..."
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="3" :md="3">
            <el-form-item label="Daily Revenue" label-width="100px">
              <el-input
                v-model="payout.list.dailyRevenue"
                placeholder="please add daily revenue..."
              >
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :lg="3" :md="3">
            <el-form-item label="Click Cap Daily" label-width="120px">
              <el-input
                v-model="payout.list.clickCapDaily"
                placeholder="please add manual click cap daily..."
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :lg="3" :md="3">
            <el-form-item label="Allow Deduct" label-width="100px">
              <el-select v-model="payout.list.allowDeduct" disabled>
                <el-option
                  v-for="item in deductOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="3" :md="3">
            <el-form-item label="Click Cap Incr Fix" label-width="120px">
              <el-input v-model="payout.list.clickCapIncrFix"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :lg="1" :md="1">
            <el-form-item label="." label-width="120px">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="addPayoutInfoItem()"
              ></el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" v-for="(item, index) in payout.list.eventCapDaily" :key="index">
          <el-col :lg="5" :md="5">
            <el-form-item
              label="Event name"
              :prop="'eventCapDaily.' + index + '.eventName'"
              :rules="[{ required: true, message: 'please add eventName', trigger: 'blur' }]"
            >
              <el-input v-model.trim="item.eventName" placeholder="please add cap eventName...">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item
              label="Revenue"
              label-width="100px"
              :prop="'eventCapDaily.' + index + '.revenue'"
              :rules="[{ required: true, message: 'please add revenue', trigger: 'blur' }]"
            >
              <el-input
                v-model="item.revenue"
                placeholder="please add revenue..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item
              label="Payout"
              :prop="'eventCapDaily.' + index + '.payout'"
              :rules="[{ required: true, message: 'please add payout', trigger: 'blur' }]"
            >
              <el-input
                v-model="item.payout"
                placeholder="please add payout..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item label="Event cap daily">
              <el-input v-model="item.capDaily" placeholder="please add capDaily..."></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :lg="2" :md="2">
            <el-form-item label="currency ">
              <el-input
                v-model="item.currency"
                disabled
                placeholder="please add currency..."
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :lg="4" :md="4">
            <el-form-item label="." label-width="120px">
              <el-button
                type="danger"
                icon="el-icon-minus"
                circle
                size="mini"
                @click="delPayoutInfoItem(index)"
              ></el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="align-r">
          <el-button size="small" @click="payout.visible = false">取消</el-button>
          <el-button size="small" type="primary" @click="updatePayoutSubmit('updatePayoutAll')"
            >提交</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="offerLinkType.visible" width="30%">
      <el-select
        v-model="offerLinkType.data.linkType"
        placeholder="请选择"
        class="w100"
        v-if="offerLinkType.data.type == 'offerLinkType'"
      >
        <el-option label="ua" value="ua"></el-option>
        <el-option label="rt" value="rt"></el-option>
      </el-select>
      <el-input
        v-model="offerLinkType.data.pid"
        placeholder="please add pid..."
        v-if="offerLinkType.data.type == 'pid'"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offerLinkType.visible = false">取 消</el-button>
        <el-button type="primary" @click="updateOfferLinkType()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑 offer Payout Type" :visible.sync="offerPayoutType.visible" width="30%">
      <el-select v-model="offerPayoutType.data.type" placeholder="请选择" class="w100">
        <el-option label="CPI" value="CPI"></el-option>
        <el-option label="CPA" value="CPA"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offerPayoutType.visible = false">取 消</el-button>
        <el-button type="primary" @click="updateOfferPayoutType()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改Source track Link" :visible.sync="offerSource.visible" width="30%">
      <el-input
        type="textarea"
        :autosize="{ minRows: 5 }"
        v-model="offerSource.sourceTrackingLink"
        placeholder="请输入Source track Link"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offerSource.visible = false">取 消</el-button>
        <el-button type="primary" @click="updateOfferSource()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改Source track Link" :visible.sync="offerSource.batchVisible" width="30%">
      <el-input
        type="textarea"
        :autosize="{ minRows: 5 }"
        v-model="offerSource.sourceTrackingLink"
        placeholder="请输入Source track Link"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offerSource.batchVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchUpdateOfferSourceLink()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 创建pb -->
    <el-dialog
      title="Replace pb"
      :width="fullWidth < 750 ? '95%' : ''"
      :visible.sync="replacePbVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <el-form :model="replacePb" ref="replacePb" :rules="rules">
        <el-form-item label="Campaign">
          <el-input v-model="replacePb.campaign"></el-input>
        </el-form-item>
        <el-form-item label="Event Callback" prop="event_callback">
          <el-input v-model="replacePb.event_callback"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="mini"
          @click="
            $refs['replacePb'].resetFields();
            replacePb.campaign = '';
            replacePb.event_callback = '';
            replacePbVisible = false;
          "
          >取消</el-button
        >
        <el-button type="success" size="mini" @click="submitReplacePb('replacePb')">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="batchImportErrorVisible" width="30%">
      <div v-for="item in batchImportError" :key="item" class="pt10 pb10 border-bottom-1">{{
        item
      }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchImportErrorVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="autoMix.batchMixErrorVisible" width="30%">
      <div v-for="item in autoMix.batchMixError" :key="item" class="pt10 pb10 border-bottom-1">{{
        item
      }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="autoMix.batchMixErrorVisible = false">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="impressionVisible"
      title="update Impression Info"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="600px"
      @close="impressionCancel()"
    >
      <el-form
        ref="impressionForm"
        :model="impressionInfo"
        label-position="right"
        :rules="impressionInfo.impressionOpen === 1 ? impressionRules : impressionRules2"
        label-width="200px"
      >
        <el-form-item
          label="impression Tracking"
          prop="impressionTracking"
          :required="impressionInfo.impressionOpen === 1 ? true : false"
          ><el-input v-model="impressionInfo.impressionTracking"></el-input
        ></el-form-item>
        <el-form-item label="impression Open">
          <el-switch
            v-model="impressionInfo.impressionOpen"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item
          label="impression ratio"
          prop="impressionRatio"
          :required="impressionInfo.impressionOpen === 1 ? true : false"
        >
          <el-input
            :disabled="!impressionInfo.impressionOpen"
            v-model="impressionInfo.impressionRatio"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="impressionVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateImpressionClick('impressionInfo')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- batch update event payout -->
    <el-dialog
      title="batch event payout"
      key="batchEventPayout"
      :visible.sync="batchEventPayout.visible"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        label-position="top"
        size="mini"
        :model="batchEventPayout.list"
        ref="batchEventPayoutInfo"
        :rules="payoutInfoRules"
        v-loading="batchEventPayout.loading"
      >
        <el-row :gutter="10">
          <el-col :lg="3" :md="3">
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="addEventPayoutInfoItem()"
              ></el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          :gutter="10"
          v-for="(item, batchEventPayoutIndex) in batchEventPayout.list.eventCapDaily"
          :key="'batchEventPayout' + batchEventPayoutIndex"
        >
          <el-col :lg="5" :md="5">
            <el-form-item
              label="Event name"
              :prop="'eventCapDaily.' + batchEventPayoutIndex + '.eventName'"
              :rules="[{ required: true, message: 'please add eventName', trigger: 'blur' }]"
            >
              <el-input v-model.trim="item.eventName" placeholder="please add eventName...">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item
              label="Revenue"
              label-width="100px"
              :prop="'eventCapDaily.' + batchEventPayoutIndex + '.revenue'"
              :rules="[{ required: true, message: 'please add revenue', trigger: 'blur' }]"
            >
              <el-input
                v-model="item.revenue"
                placeholder="please add revenue..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item
              label="Payout"
              :prop="'eventCapDaily.' + batchEventPayoutIndex + '.payout'"
              :rules="[{ required: true, message: 'please add payout', trigger: 'blur' }]"
            >
              <el-input
                v-model="item.payout"
                placeholder="please add payout..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item label="Event cap daily">
              <el-input v-model="item.capDaily" placeholder="please add capDaily..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="4" :md="4">
            <el-form-item label="." label-width="120px">
              <el-button
                type="danger"
                icon="el-icon-minus"
                circle
                size="mini"
                @click="delEventPayoutInfoItem(batchEventPayoutIndex)"
              ></el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="align-r">
          <el-button size="small" @click="batchEventPayout.visible = false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="updateEventPayoutSubmit('batchEventPayoutInfo')"
            >提交</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <!-- // 编辑offer manager 和 sub rule -->
    <el-dialog
      :visible.sync="upManagerAndSubRuleVisible"
      title="update SubRule"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="600px"
    >
      <el-form
        ref="upManagerAndSubRuleForm"
        :model="upManagerAndSubRule"
        label-position="right"
        label-width="129px"
      >
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="sub rule" prop="">
              <el-select
                v-model="upManagerAndSubRule.ruleId"
                placeholder="Please select sub rule"
                filterable
              >
                <el-option
                  v-for="item in options.rule"
                  :key="item.ruleId"
                  :label="item.ruleName"
                  :value="item.ruleId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="upManagerAndSubRuleVisible = false">取 消</el-button>
        <el-button type="primary" @click="upManagerAndSubRuleClick" :loading="saveBtnLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import RankingSearchCon from '../controllers/product/Ranking&search';
  export default {
    name: 'RankingSearch',
    ...RankingSearchCon,
  };
</script>
<style>
  .uploadBtn,
  .uploadBtn .el-upload,
  .uploadBtn .el-button {
    width: 100%;
  }
  .uploadBtn .el-button--small {
    padding: 7px 15px;
  }
</style>

<style lang="scss" scoped>
  .icon {
    width: 40px;
    height: 40px;
  }

  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .upload {
    display: inline-block;
  }

  .martop10 {
    margin-top: 10px;
  }
  .el-tag {
    white-space: normal;
    height: auto;
    text-align: center;
  }
</style>
