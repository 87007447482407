<style scoped></style>
<template>
  <div class="upload">
    <el-upload
      :action="action"
      :data="data"
      :before-upload="handleBeforeUpload"
      :on-progress="handlePreview"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      :show-file-list="false"
    >
      <el-button :type="buttonType" size="small" :loading="loading" slot="trigger"
        >{{ buttonTxt }}<i class="el-icon-upload el-icon--right" v-if="isIcon"></i>
      </el-button>
    </el-upload>
  </div>
</template>
<script>
  export default {
    props: {
      buttonTxt: {
        type: String,
        default: 'Upload',
      },
      action: {
        type: String,
        required: true,
      },
      buttonType: {
        type: String,
        default: 'primary',
      },
      isIcon: {
        type: Boolean,
        default: true,
      },
      data: null,
      fileSize: null, // 文件大小限制，单位为K
      uploadId: null,
      name: null,
      onSuccess: Function,
      onProgress: Function,
      beforeUpload: Function,
      fileType: null, // 文件类型限制用|分隔 如png|jpg|jpeg|gif
    },
    data() {
      return {
        loading: false,
      };
    },
    methods: {
      handleBeforeUpload(file) {
        const fileName = file.name;
        const ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        if (this.fileType) {
          // 限制文件格式
          if (this.fileType.indexOf(ext) < 0) {
            this.$message.error('文件类型不合法，请上传' + this.fileType);
            return false;
          }
        }
        if (this.fileSize) {
          // 限制文件大小，单位为k
          if (file.size / 1024 > this.fileSize) {
            this.$message.error('文件大小限制为' + this.fileSize + 'k');
            return false;
          }
        }
        this.loading = true; // 开启上传中状态
        if (this.beforeUpload) {
          const arg = [].slice.call(arguments);
          if (this.uploadId) {
            arg.push(this.uploadId);
          }
          this.beforeUpload.apply(this, arg);
        }
      },
      handlePreview() {
        this.$emit('on-progress');
      },
      handleUploadSuccess(response) {
        this.loading = false; // 关闭上传中状态
        if (this.onSuccess) {
          if (response.code === 200) {
            const arg = [].slice.call(arguments);
            if (this.uploadId) {
              arg.push(this.uploadId);
            }
            arg.concat(response.result);
            this.onSuccess.apply(this, arg);
          } else {
            this.$message.error(response.message);
          }
        }
      },
      handleUploadError() {
        this.loading = false; // 关闭上传中状态
        this.$message.error('上传失败');
      },
    },
  };
</script>
